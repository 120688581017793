import request from './ajax'
// 技能服务大厅
export const getServices = (data) =>
  request({
    method: 'get',
    url: '/service/index',
    params: data
  })

export const getServiceInfo = (data) =>
  request({
    method: 'get',
    url: '/service/info',
    params: data
  })

// 推荐技能
export const getRecommandskills = (data, token) =>
  request({
    method: 'get',
    url: '/task/recommandskill',
    params: data,
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const getSkillsCategory = (data) =>
  request({
    method: 'get',
    url: '/service/category/list',
    params: data
  })

export const getSkills = (data) =>
  request({
    method: 'get',
    url: '/service/skills',
    params: data
  })

export const createService = (data, token) =>
  request({
    method: 'get',
    url: '/service/create',
    params: data,
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const updateService = (data, token) =>
  request({
    method: 'get',
    url: '/service/update',
    params: data,
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const getServiceAuditResult = (data, token) =>
  request({
    method: 'get',
    url: '/service/auditres',
    params: data,
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })

export const getRecommendFreelance = (data) =>
  request({
    method: 'get',
    url: '/service/recommendfreelance',
    params: data
  })
  

  
