<template>
  <div class="CupSmallStart">
    <div class="CupSmallStart-box">
      <div class="CupSmallStart-box-top" @click="toInfoPage()" :style="{ 'background-image': 'url('+objectData.thumb+')', 'background-size': '100%' }"></div>
      <div class="CupSmallStart-box-bottom">
        <div class="CupSmallStart-box-bottom-center">
          <!-- 文字和图片 -->
          <div class="CupSmallStart-box-bottom-center-img-font">
            <!-- 分为头像姓名 图标 -->
            <div class="CupSmallStart-box-bottom-center-img-fonts">
              <img :src="objectData.user_avatar" alt="" />
            </div>
            <span>{{ objectData.user_name }}</span>
            <div class="CupSmallStart-box-bottom-center-img-fonts1">
              <img src="@/icons/svg/task/资深认证16X16.svg" alt="" />
            </div>
          </div>
          <!-- 文字 -->
          <div class="CupSmallStart-box-bottom-center-font">
            <p>{{ objectData.subject }}</p>
            <div class="start">
              <el-rate
                v-model="rateLevel"
                disabled
                show-score
                text-color="#ff9900"
                score-template="{value}"
              >
              </el-rate>
              <span>(0个评价)</span>
            </div>
          </div>
          <div class="CupSmallStart-box-bottom-font">
            <p>￥{{ objectData.price_format }}/小时</p>
            <!-- <p>30天交付</p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    objectData: {
        type: Object,
        default: () => {}
    }
  },
  data() {
    return {
      rateLevel: 5.0
    };
  },
  methods:{
    toInfoPage(){
      this.$router.push('/service/main/'+ this.objectData.id)
    }
  }
};
</script>

<style lang="less" scoped>
.CupSmallStart {
  height: 100%;
  .CupSmallStart-box {
    width: 208px;
    height: 270px;
    background: #ffffff;
    margin: 0 13px 20px 0 ;
    .CupSmallStart-box-top {
      cursor: pointer;
      height: 140px;
      background-image: url(~@/assets/task/151a1d54726963.5966f98ace97d.jpg);
      background-size: cover;
    }
    .CupSmallStart-box-bottom {
      .CupSmallStart-box-bottom-center {
        width: 186px;
        margin: 0 auto;
        height: 130px;
      }
      .CupSmallStart-box-bottom-center-img-font {
        height: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        .CupSmallStart-box-bottom-center-img-fonts {
          img {
            width: 24px;
            height: 24px;
            border-radius: 50%;
          }
        }
        span {
          margin-left: -80px;
        }
        /* 认证资格 */
        .CupSmallStart-box-bottom-center-img-fonts1 {
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
      /* 文字距离上面的距离 */
      .CupSmallStart-box-bottom-center-font {
        margin-top: 20px;
        color: #575757;
        height: 40px;
        border-bottom: 1px solid #ddd;
        p{
          font-size: 14px;
          color: #575757;
        }
        .start{
          margin-top: 6px;
          display: flex;
        }
        /deep/.el-rate{
          margin-top: -2px;
        }
        span{
          font-size: 12px;
          margin-left: 10px;
        }
      }
      /* 底部每小时的钱数 */
      .CupSmallStart-box-bottom-font{
        display: flex;
        margin-top: 8px;
        justify-content: space-between;
        p{
          font-size: 14px;
          color: #575757;
        }
      }
    }
  }
}
/deep/.el-rate__icon{
    font-size: 12px;
    margin-right: 0px;
}
/deep/.el-rate__text{
    font-size: 12px;
    margin-left: 5px;
}
</style>
