<template>
    <div class="banner">
        <div class="banner-main">
            <img src="@/assets/banner/banner-技能服务大厅2.jpg" alt="" />
        </div>
        <!-- 中间内容部分 -->
        <div class="banner-center">
            <!-- 上部内容 -->
            <div class="banner-center-top">
                <div class="banner-center-top-font">
                    <div class="banner-center-top-font1">
                        优秀人才推荐
                        <div class="banner-center-top-font1-img">
                            <img src="@/icons/svg/audit/疑问16X16.svg" alt="" />
                            <span>如何成为优秀人才</span>
                        </div>
                    </div>
                    <div class="banner-center-top-font2">
                        <!-- 点击换一批发请求 -->
                       <!-- <img src="@/icons/svg/audit/换一批16X16.svg" alt="" />
                        <span>换一批</span> -->
                    </div>
                </div>
                <div class="banner-center-top-img">
                    <div class="banner-center-top-img-box">
                        <RecommendFreelance :objectData="itemData" v-for="( itemData, index ) in RecommendFreelanceData" :key="index"></RecommendFreelance>
                    </div>
                </div>
            </div>
            <!-- 底部内容 -->
            <div class="banner-center-bottom">
                <!-- 分为左右两部分 -->
                <div class="banner-center-bottom-left">
                    <div class="banner-center-bottom-left-center">
                        <!--最近搜索  -->
                        <div class="banner-center-bottom-left-search">
                            <strong>最近搜索</strong>
                            <a href="#" @click="clearSearch">清除</a>
                            <!-- 下面是搜索的内容 -->
                            <div class="banner-center-bottom-left-search-center">
                            </div>
                        </div>
                        <!-- 行业分类 -->
                        <div class="banner-center-bottom-left-classify">
                            <el-row class="tac">
                                <h5>行业分类</h5>
                                <el-col :span="14" style="width: 245px">
                                    <el-menu
                                        default-active="2"
                                        class="el-menu-vertical-demo"
                                        @open="handleOpen"
                                        @close="handleClose"
                                    >
                                    <el-submenu :index="catIndex.toString()" v-for="( catItem, catIndex ) in categorys" :key="catIndex">
                                        <template slot="title">
                                            <span slot="title"> {{ catItem.name }} </span>
                                        </template>
                                        <el-menu-item-group>
                                            <el-menu-item
                                            :index="catIndex+'-'+cindex"
                                            v-for="( citem, cindex ) in catItem.children"
                                            :key="cindex">
                                                <el-radio v-model="searchParams.cat_id" :label="citem.id" @change="changeCategory( citem.id, citem.name )"> {{ citem.name }} </el-radio>
                                            </el-menu-item>
                                        </el-menu-item-group>
                                    </el-submenu>

                                    </el-menu>
                                </el-col>
                            </el-row>
                        </div>
                        <!-- 计价方式 -->
                        <div class="banner-center-bottom-left-money">
                            <div class="banner-center-bottom-left-money-box">
                                <h2>计价方式</h2>
                                <div class="banner-center-bottom-left-money-radio" >
                                    <el-radio-group v-model="searchParams.pay_way" @change="search">
                                        <el-radio label="2" class="hall-money">时薪</el-radio>
                                        <el-radio label="1">一口价</el-radio>
                                    </el-radio-group>
                                </div>
                                <div class="banner-center-bottom-left-money-button">
                                    <el-input type="number" v-model="searchParams.price_interval[0]" size="small" :readonly="searchParams.pay_way==''" placeholder="￥最低金额" @blur="minPrice" />
                                    &nbsp;&nbsp;至&nbsp;&nbsp;
                                    <el-input type="number" v-model="searchParams.price_interval[1]" size="small" :readonly="searchParams.pay_way==''" placeholder="￥最高金额" @blur="minPrice" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 右侧 -->
                <div class="banner-center-bottom-right">
                    <!-- 搜索框 -->
                    <div class="banner-center-bottom-right-search">
                        <input type="text" placeholder="输入服务的标题/名称来检索" v-on:keyup.enter="search" v-model="searchParams.subject" />
                        <i class="el-icon-search"></i>
                        <!-- 后期搜索 会调用接口 -->
                        <button @click="search">搜索关键字</button>
                    </div>
                    <!-- 搜索框下面显示的内容 -->
                    <div class="banner-center-bottom-right-search-border">
                        <div
                            class="banner-center-bottom-right-search-border1"
                            v-for="( searchItem, searchIndex ) in searchParams"
                            :key="searchIndex"
                            v-show="searchItem != ''"
                        >
                            <div class="banner-center-bottom-right-search-border1-box" v-show="searchIndex == 'subject'">
                                标题：{{ searchItem }}
                                <span style="color: #fff" @click="clearTag( searchIndex )">X</span>
                            </div>
                            <div class="banner-center-bottom-right-search-border1-box" v-show="searchIndex == 'pay_way'">
                                计价方式：<i v-for="payitem in payWayList" :key="payitem.value" v-show="payitem.value==searchItem" > {{ payitem.label }} </i>
                                <span style="color: #fff" @click="clearTag( searchIndex )">X</span>
                            </div>
                            <div class="banner-center-bottom-right-search-border1-box" v-show="searchIndex == 'sort'">
                                排序：<i v-for="oritem in orderByList" :key="oritem.value" v-show="oritem.value==searchItem" > {{ oritem.label }} </i>
                                <span style="color: #fff" @click="clearTag( searchIndex )">X</span>
                            </div>
                            <div class="banner-center-bottom-right-search-border1-box" v-show="searchIndex == 'cat_id'">
                                分类：{{ searchParams.cat_name }}
                                <span style="color: #fff" @click="clearTag( searchIndex )">X</span>
                            </div>
                             <div class="banner-center-bottom-right-search-border1-box" v-show="searchIndex == 'price_interval' && searchItem[0] && searchItem[1]">
                                金额： {{ searchItem[0] }} - {{ searchItem[1] }}
                                <span style="color: #fff" @click="clearTag( searchIndex )">X</span>
                            </div>
                        </div>
                    </div>
                    <!-- 搜素到的结果 -->
                    <div class="banner-search-res">
                        <div class="banner-search-res-border">
                            <p>共找到 {{ pages.total }} 个结果</p>
                            <el-select
                                v-model="searchParams.sort"
                                clearable
                                placeholder="综合排序"
                                @change="search"
                            >
                                <el-option
                                    v-for="item in orderByList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <!-- 搜索到的内容区域 -->
                    <div class="banner-search-conter">
                        <div class="banner-search-content-center">
                            <CupSmallStart :objectData="serviceItem" v-for="( serviceItem, i ) in serviceData" :key="i"></CupSmallStart>
                        </div>
                    </div>
                    <!-- 分页器 -->
                    <div class="block">
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="pages.page"
                            :page-sizes="[100, 200, 300, 400]"
                            :page-size="pages.page_size"
                            layout="prev, pager, next,->,total,jumper"
                            :total="pages.total"
                            background
							:hide-on-single-page="true"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getRecommendFreelance, getServices, getSkillsCategory } from '@/api/service'

import RecommendFreelance from "@/components/RecommendFreelance";
import CupSmallStart from "@/components/CupSmallStart";
export default {
    name: "skillhall",
    data() {
        return {
            radio: "1",
            orderByList: [
                {
                    value: 'desc',
                    label: "最新发布",
                },
                {
                    value: 'asc',
                    label: "综合排序",
                },
            ],
            payWayList: [
                {
                    value: 1,
                    label: '一口价'
                },
                {
                    value: 2,
                    label: '时薪'
                },
            ],
            // 分类 一口价 关键字
            searchParams: {
                sort: '',
                subject: "", // 关键字
                pay_way: "", //收费类型
                price_interval: [],
                cat_id: 0
            },
            pageParams: { page: 1, page_size: 10 },
            pages: { page: 1, page_size: 10, total: 1 },
            RecommendFreelanceData: [],
            serviceData: [],
            categorys: [],
        };
    },
    components: {
      RecommendFreelance,
      CupSmallStart,
    },
    created(){
        this.loadRecommendFreelance()
        this.loadServiceList( this.pageParams )
        this.loadCategorys()
    },
    methods: {
        async loadRecommendFreelance(){
            const res = await getRecommendFreelance( { page:1, page_size: 4} )
            if( res.statusCode == 200 ){
                this.RecommendFreelanceData = res.data
            }
        },
        async loadServiceList( data ){
            const res = await getServices( data )
            if( res.statusCode == 200 ){
                this.serviceData = res.data.list
                this.pages = {
                    page: Number( res.data.pages.page ),
                    page_size: Number( res.data.pages.page_size ),
                    total: Number( res.data.pages.total )
                }
            }
        },
        async loadCategorys(){
            const res = await getSkillsCategory()
            if( res.statusCode == 200 ){
                this.categorys = res.data
            }
        },
        handleSizeChange(val) {
            this.pageParams.page_size = val;
            this.loadServiceList(this.pageParams);
        },
        handleCurrentChange(val) {
            this.pageParams.page = val;
            this.loadServiceList(this.pageParams);
        },
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
        changeCategory( id, name ){
            this.searchParams.cat_id = id
            this.searchParams.cat_name = name
            this.search()
        },
        minPrice(){
            if( this.searchParams.price_interval[1] < this.searchParams.price_interval[0] ){
                this.$message.error('最高金额不能小于最低金额')
                this.searchParams.price_interval = []
            }else{
                if( this.searchParams.price_interval.length > 0 ){
                    this.search()
                }
            }
        },
        clearTag( tagIndex ){
            switch( tagIndex ){
                case 'subject':
                    this.searchParams.subject = ''
                    break;
                case 'sort':
                    this.searchParams.sort = ''
                    break;
                case 'pay_way':
                    this.searchParams.pay_way = ''
                    break;
                case 'cat_id':
                    this.searchParams.cat_id = ''
                    this.searchParams.cat_name = ''
                    break;
                case 'price_interval':
                    this.searchParams.price_interval = []
                    break;
            }
            this.loadServiceList( this.searchParams )
        },
        search(){
            this.loadServiceList( this.searchParams )
        },
        clearSearch(){

        },
    },
};
</script>

<style lang="less" scoped>
/deep/.el-icon-caret-right:before{
    content: unset;
}
/deep/.el-submenu .el-menu-item{
    line-height: 35px;
}
/* 图片部分 */
.banner {
    background-color: #f8f8f8;
}
.banner-main {
   width: 1200px;
   height: 80px;
   margin: 0 auto;
   margin-top: 20px;
   img{
     width: 1200px;
     height: 80px;
     object-fit: cover;
   }
}
/* 下面内容部分 */
.banner-center {
    width: 1200px;
    margin: 0 auto;
    margin-top: 20px;
    /* 顶部文字和图片内容 */
    .banner-center-top {
        /* 上面文字部分 */
        .banner-center-top-font {
            display: flex;
            img {
                width: 16px;
                height: 16px;
            }
            span {
                font-size: 12px;
            }
            justify-content: space-between;
            .banner-center-top-font1 {
                font-size: 24px;
                .banner-center-top-font1-img {
                    float: right;
                    margin-left: 20px;
                    span {
                        margin-left: 10px;
                    }
                    img {
                        position: relative;
                        top: 2px;
                    }
                }
            }
            .banner-center-top-font2 {
				cursor: pointer;
                img {
                    margin-right: 10px;
                    position: relative;
                    top: 2px;
                }
                position: relative;
                top: 3px;
            }
        }
        /* 上面图片区域 */
        .banner-center-top-img {
            margin-top: 13px;
            width: 1200px;
            .banner-center-top-img-box {
                width: 1220px;
                display: flex;
                flex-wrap: wrap;
            }
        }
    }
    /* 底部搜索内容 */
    .banner-center-bottom {
        margin-top: 15px;
        display: flex;
        /* 左侧 */
        .banner-center-bottom-left {
            width: 285px;
            max-height: 708px;
            background: #fff;
            display: flex;
			padding-bottom: 40px;
            flex-wrap: wrap;
            .banner-center-bottom-left-center {
                width: 245px;
                margin: 0 auto;
            }
            /* 最近搜索 */
            .banner-center-bottom-left-search {
                width: 245px;
                margin-top: 40px;
                max-height: 108px;
                h2 {
                    font-size: 18px;
                }
                a {
                    margin-left: 10px;
                    color: #575757;
                    font-size: 10px;
                }
                .banner-center-bottom-left-search-center {
                    margin-top: 20px;
                    display: flex;
                    flex-wrap: wrap;
                    span {
                        display: block;
                        padding: 4px 9px;
                        border: 1px solid #b5b5b5;
                        border-radius: 10px;
                        margin: 0 10px 10px 0px;
                        font-size: 12px;
                    }
                }
            }
            /* 行业分类 */
            .banner-center-bottom-left-classify {
                max-height: 407px;
                width: 245px;
                border-top: 1px solid #b5b5b5;
                border-bottom: 1px solid #b5b5b5;
                overflow: hidden;
                h5 {
                    margin-top: 20px;
                    font-size: 18px;
                    margin-bottom: 10px;
                }
                .tac {
                    width: 245px;
                }
                /deep/ .el-submenu__title {
                    padding: 0px !important;
                }
                /deep/ .el-menu-item {
                    padding: 0 !important;
                }
                /deep/ .el-submenu__title
                {
                    line-height: 34px;
                    height: 34px;
                }
                /deep/ .el-menu-item{
                    height: 34px;
                    font-size: 12px;
                    position: relative;
                    top: -15px;
                }
                /deep/ .el-radio__label{
                    font-size: 12px;
                }
            }
            /* 计价方式 */
            .banner-center-bottom-left-money {
                margin-top: 20px;
                .banner-center-bottom-left-money-box {
                    width: 245px;
                    height: 96px;
                    h2 {
                        font-size: 18px;
                    }
                    .banner-center-bottom-left-money-radio {
                        margin-top: 20px;
                        margin-bottom: 10px;
                        .hall-money {
                            margin-left: 30px;
                            margin-right: 50px;
                        }
                    }
                    .banner-center-bottom-left-money-button {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        line-height: 32px;
                        input {
                            width: 100px;
                            height: 32px;
                            border: 1px solid #b5b5b5;
                            color: #575757;
                            font-size: 14px;
                            background-color: #fff;
                        }
                    }
                }
            }
        }
        /* 右侧 */
        .banner-center-bottom-right {
            margin-left: 20px;
            /* 搜索框 */
            .banner-center-bottom-right-search {
                font-size: 24px;
                .el-icon-search {
                    color: #575757;
                    position: relative;
                    left: -730px;
                    top: 5px;
                }
                input {
                    width: 745px;
                    height: 50px;
                    background: #ffffff;
                    text-indent: 74px;
                    font-size: 18px;
                    outline: none;
                }
                button {
                    width: 150px;
                    height: 50.5px;
                    background: #00a2e6;
                    font-size: 18px;
                    margin-left: -24px;
                }
            }
            /*  搜索框下面显示的内容*/
            .banner-center-bottom-right-search-border {
                margin-top: 10px;
                display: flex;
                line-height: 20px;
                font-size: 12px;
                .banner-center-bottom-right-search-border1,
                .banner-center-bottom-right-search-border2,
                .banner-center-bottom-right-search-border3 {
                    display: flex;
                }
                .banner-center-bottom-right-search-border1-box,
                .banner-center-bottom-right-search-border2-box,
                .banner-center-bottom-right-search-border3-box {
                    background-color: #00a2e6;
                    padding: 0 14px;
                    border-radius: 10px;
                    color: #fff;
                    cursor: pointer;
                    margin-right: 10px;
                    span{
                        position: relative;
                        right: -10px;
                        top: -4px;
                    }
                }
            }
            /* 搜素到的结果 */
            .banner-search-res {
                margin-top: 20px;
                p {
                    line-height: 32px;
                    font-size: 14px;
                    color: #474747;
                }
                .banner-search-res-border {
                    display: flex;
                    justify-content: space-between;
                }
            }
            /* 搜索到的内容 */
            .banner-search-conter {
                width: 895px;
                margin-top: 20px;
                .banner-search-content-center {
                    width: 908px;
                    display: flex;
                    flex-wrap: wrap;
                    align-content: center;
                }
            }
        }
    }
    /* 分页器的内容 */
    .block {
        width: 550px;
        margin: 0 auto;
    }
}
/deep/.el-radio__label {
    font-size: 17px;
}
/* 修改 el-row的设置 */

.el-row {
    width: 245px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
}

.el-card {
    min-width: 100%;
    height: 100%; /*  高度要设置百分比才可以 */
    margin-right: 20px;
    transition: all 0.5s;
}
/deep/ .el-input__inner {
    border-radius: 0;
}
/deep/.el-menu-vertical-demo{
  max-height: 350px;
  overflow: auto;
  border-right:none ;
}
</style>
