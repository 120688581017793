<template>
    <div class="cup">
        <div class="Cup-BigStart">
            <!-- 头部包含文字和图片 -->
            <div class="Cup-BigStart-top" @click="$router.push('/freelancer/info/'+objectData.user_id)" :style="{ 'background-image': 'url('+objectData.user_avatar+')', 'background-size': '100%' }">
                <img src="@/icons/svg/task/资深认证16X16.svg" alt="" />
                <!-- 底部的遮罩 -->
                <div class="Cup-BigStart-top-shade">
                    <div class="Cup-BigStart-top-shade-font" >
                        <span v-for="(skill, key) in objectData.skills" :key="key"> {{ skill }}</span>
                    </div>
                </div>
            </div>
            <!-- 底部 -->
            <div class="Cup-BigStart-bottom">
                <div class="Cup-BigStart-bottom1">
                    <el-tooltip class="item" effect="dark" :content="objectData.user_name" placement="bottom-start">
                        <strong> {{ clearName(objectData.user_name, 0, 4) }}</strong>
                    </el-tooltip>
                    <span>￥ {{ objectData.hourly_rate }} 元/小时</span>
                </div>
                <div class="Cup-BigStart-bottom2">
                    <div class="Cup-BigStart-bottom21">
                        <el-rate
                            v-model="rateLevel"
                            disabled
                            show-score
                            text-color="#000"
                            score-template="{value}"
                        >
                        </el-rate>
                        <!-- 评论 -->
                        <div class="Cup-BigStart-botom21-comment">
                            ( {{ objectData.review_count_2 }} 个评论)
                        </div>
                        <!-- 服务 -->
                        <div class="Cup-BigStart-bottom21-serve">
                            {{ objectData.service_count }}个服务
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CupBigStart",
    props:{
        objectData: {
            type: Object,
            default: {}
        }
    },
    data(){
        return{
            rateLevel: 5.0
        }
    },
    methods:{
        // 字符串截取 包含对中文处理,str需截取字符串,start开始截取位置,n截取长度
        clearName(str, start, n) { // eslint-disable-line
            if (str.replace(/[\u4e00-\u9fa5]/g, '**').length <= n) {
            return str;
            }
            let len = 0;
            let tmpStr = '';
            for (let i = start; i < str.length; i++) { // 遍历字符串
            if (/[\u4e00-\u9fa5]/.test(str[i])) { // 中文 长度为两字节
                len += 2;
            } else {
                len += 1;
            }
            if (len > n) {
                break;
            } else {
                tmpStr += str[i];
            }
            }
            return tmpStr + '...';
        }
    }
};
</script>

<style lang="less" scoped>
.Cup-BigStart {
    margin: 0 auto;
    width: 285px;
    height: 400px;
    background: #fff;
    margin-right: 20px;
    .Cup-BigStart-top {
        cursor: pointer;
        height: 300px;
        background-image: url(~@/assets/task/头像1 拷贝.jpg);
        position: relative;
        img {
            width: 16px;
            height: 16px;
            position: absolute;
            top: 20px;
            right: 20px;
        }
        .Cup-BigStart-top-shade {
            width: 285px;
            height: 60px;
            background: #575757;
            opacity: 0.6;
            position: absolute;
            bottom: 0;
            .Cup-BigStart-top-shade-font {
                width: 245px;
                height: 39px;
                font-size: 14px;
                color: #fff;
                line-height: 21px;
                margin-top: 10px;
                margin-left: 15px;
                display: -webkit-box;
                overflow: hidden;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }
    .Cup-BigStart-bottom {
        height: 100px;
        width: 245px;
        margin: 0 auto;
        .Cup-BigStart-bottom1 {
            line-height: 50px;
            margin-top: 7px;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #dcdddd;
            strong {
                float: left;
                font-size: 24px;
            }
            span {
                font-size: 18px;
                float: right;
                color: #575757;
            }
        }
        .Cup-BigStart-bottom2{
            height: 12px;
            margin-top: 10px;
            .Cup-BigStart-bottom21{
                display: flex;
                font-size: 12px;
                .Cup-BigStart-botom21-comment{
                    margin-left: 10px;
                }
                .Cup-BigStart-bottom21-serve{
                    position: relative;
                    left: 55px;
                }
            }

        }
    }
}
/deep/.el-rate__icon{
    font-size: 12px;
    margin-right: 0px;
}
/deep/.el-rate__text{
    font-size: 12px;
    margin-left: 5px;
}
</style>
